import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Modal } from "react-bootstrap";
import TinInfo from "../../components/TinInfo";
// let fileName = "newData.xlsx";
// let workbook = excel.readFile(fileName);

export const AddModal = ({ isShow, handleClose, data }) => {

    return (
        <>
            <Modal
                show={isShow}
                onHide={() => {
                    handleClose()
                }}
                size="lg"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title>
                        ເພີ່ມ
                    </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <TinInfo data={data} />
                </Modal.Body>

            </Modal>
        </>

    );
};





