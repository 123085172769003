import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   Container,
//   CssBaseline,
//   Avatar,
//   Box,
//   Typography,
// } from "@mui/material";
// import { LockOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import axios from "axios";

export const Login = () => {
  const history = useHistory()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false)
  const [errorText,setErrorText] = useState("")
  const login = async () => {
    try {
      const data = {
        username,
        password
      }
      const login = await axios.post("https://uattaxris.mmoney.la/login", data)
      // console.log({login})
      if (login.data.status == true) {
        localStorage.setItem("USER_KEY", login?.data?.token)
        localStorage.setItem("ORG_CD",login?.data?.ORG_CD)
        localStorage.setItem("USER_LOGIN",username)
        setErrorText("")
        setIsError(false)
        history.push("/dashboard")
      } else {
        // console.log({login})
        setErrorText(login.data.data)
        setIsError(true)
      }
    } catch (error) {
      console.log(error.response);
      setIsError(true)
    }


  }
  return (
    <>
      <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {/* <div className="kk">

            <h1 className="login-text">
              <div className="top">
              Login
              </div>
              
              <div className="bottom">
              </div>
            </h1>

          </div> */}

          <div style={{fontSize:50,fontWeight:'bolder'}}>
            Login
          </div>

          <img src={require('../../images/taxlogo.png')}/>
<br/>
          <div>
            <a>UserName</a>
            <input
              className="form-control"
              margin="normal"
              required
              id="username"
              label="username"
              name="phon"
              autoComplete="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <br />
          <div>
            <a>Password</a>
            <input
              className="form-control"
              required
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          {isError ? <p style={{ color: 'red' }}>{errorText}</p> : ""}
          <br />
          <button
            className="btn btn-success"
            type="submit"
            variant="contained"
            onClick={()=>login()}
          >
            Sign In
          </button>
        </div>

      </div>

    </>

  );
};
