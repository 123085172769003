import * as React from "react";
// import { logOut } from "../helpers";
import { useHistory } from "react-router-dom";

export default function Layout({ children }) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  // const _logOut = async () => {
  //   await logOut();
  //   history.push("/login");
  // };
  return (
    <>
      <div style={{ display: 'flex' }}>
        {/* <div className='bg-dark' style={{ height: '100vh', width: open ? 200 : 70, overflowY: "scroll" }}>
          <br />
          <br />
          <br />
          <div className="nav-item" onClick={() => history.push('/home')}>
            <a style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
              <i style={{ fontSize: 30 }} class="bi bi-house"></i>&nbsp;&nbsp;
              {
                open ?
                  <a>Home</a> : ""
              }
            </a>
          </div>
          <div className="nav-item" onClick={() => history.push('/payment')}>
            <a style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
              <i style={{ fontSize: 30 }} class="bi bi-paypal"></i>&nbsp;&nbsp;
              {
                open ?
                  <a>Payment</a> : ""
              }
            </a>
          </div>
          <div className="nav-item" onClick={() => history.push('/dept')}>
            <a style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
              <i style={{ fontSize: 30 }} class="bi bi-receipt"></i>&nbsp;&nbsp;
              {
                open ?
                  <a>Dept</a> : ""
              }
            </a>
          </div>
          <div className="nav-item" onClick={() => history.push('/seller')}>
            <a style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <i style={{ fontSize: 30 }} class="bi bi-people-fill"></i>&nbsp;&nbsp;
              {
                open ?
                  <a>Sellers</a> : ""
              }
            </a>
          </div>
          <div className="nav-item" onClick={() => history.push('/employee')}>
            <a style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <i style={{ fontSize: 30 }} class="bi bi-person-workspace"></i>&nbsp;&nbsp;
              {
                open ?
                  <a>Employees</a> : ""
              }
            </a>
          </div>

        </div> */}
        {/* <div style={{ width: '100%',height:'20vh' }}> */}
          {/* <div className="bg-dark" style={{ width: '100%', height: 50, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0 20px' }}> */}
            {/* <a style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
              <i style={{ fontSize: 30, color: 'white' }} class="bi bi-list"></i>
            </a> */}
            {/* <a style={{ cursor: 'pointer', color: 'white' }} onClick={_logOut} >LogOut</a> */}

          {/* </div> */}
          {/* <div style={{ width: '100%',height:'93vh', padding: 20,overflowY:'scroll' }}> */}
            {children}
          {/* </div> */}
        {/* </div> */}

      </div>
    </>

  );
}
