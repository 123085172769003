import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ProtectRoute } from "./ProtectRoute";
import { Login } from "../pages/login/Login";
import { Home } from "../pages/home/Home";
import Layout from "../components/LayOut";
const Index = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        {/* <Route
          render={() => (
            <> */}
              <Layout>
                <ProtectRoute path="/dashboard" exact component={Home} />
              </Layout>
            {/* </>
          )}
        /> */}
      </Switch>
    </Router>
  );
};

export default Index;
