import React from 'react'

export default function TinInfo({ data }) {
    return (
        <div className="container" style={{maxHeight:window.innerHeight -100,overflowY:'scroll',fontSize:14}}>
            <div className="row">
                <div className="col-6 text-end p-0">
                    ORG_CD&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.ORG_CD}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    ORG_CD_NM&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.ORG_CD_NM}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    TIN&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.TIN}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    CO_NM&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.CO_NM}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    TEL_NO&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.TEL_NO}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    ETPS_FORM_KIND_TP_NM&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.ETPS_FORM_KIND_TP_NM}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    MOIC_ETPS_ID&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.MOIC_ETPS_ID}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    ERC_NO&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.ERC_NO}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    ERC_ISSU_DD&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.ERC_ISSU_DD}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    BUSN_TP_NM&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.BUSN_TP_NM}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    MAIN_BUSN_SECT_TP_NM&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.MAIN_BUSN_SECT_TP_NM}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    MAIN_LSIC_CD&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.MAIN_LSIC_CD}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    BASIC_ADDR_NM&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.BASIC_ADDR_NM}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    OPR_ST_DD&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.OPR_ST_DD}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    BUSN_OPR_STAT_TP&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.BUSN_OPR_STAT_TP}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    FILING_YN&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.FILING_YN}
                </div>
                {/*  */}
                <div className="col-6 text-end p-0">
                    PAY_YN&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.PAY_YN}
                </div>
                {/*  */}
                {/* <div className="col-6 text-end p-0">
                    ADDR_LOCATION&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.ADDR_LOCATION}
                </div> */}
                {/*  */}
                {/* <div className="col-6 text-end p-0">
                    ADDR_IMG&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.ADDR_IMG}
                </div> */}
                {/*  */}
                {/* <div className="col-6 text-end p-0">
                    ADDR_MAP_URL&nbsp;:&nbsp;
                </div>
                <div className="col-6 p-0">
                    {data?.ADDR_MAP_URL}
                </div> */}
                <img src={data?.ADDR_IMG} style={{maxWidth:700}} />
                {/*  */}

            </div>
        </div>
    )
}
