
export const getUserFromLCS = () => {
  return localStorage.getItem("USER_KEY");
};

export const useAuth = () => {
  const user = localStorage.getItem("USER_KEY");
  if (user !== null) {
    return true;
  } else {
    return false;
  }
};

export const removeUserFromLCS = () => {
  localStorage.removeItem("USER_KEY");
};

export const logOut = async () => {
  this.removeUserFromLCS();
};
