import "./App.css";
import Route from "./route";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'mapbox-gl/dist/mapbox-gl.css';

axios.defaults.baseURL = "https://uattaxris.mmoney.la";
function App() {
  return (
    <div>
      {/* <ThemeProvider theme={theme}> */}
        <Route />
      {/* </ThemeProvider> */}
    </div>
  );
}

export default App;
