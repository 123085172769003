import React, { useEffect, useState, useRef, useSyncExternalStore } from "react";
import axios from "axios";
// import { getUserFromLCS } from "../../helpers";
// import { Add } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import { AddModal } from "./Addmodel";
import TinInfo from "../../components/TinInfo";
import 'react-toastify/dist/ReactToastify.css';
//import "mapbox-gl/dist/mapbox-gl.css";



// import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// import MapboxWorker from 'worker-loader'; 
// import { io } from "socket.io-client";
// import { client } from 'websocket';
// import WebSocket from 'ws';
// import { client } from 'websocket';


// import "mapbox-gl/dist/mapbox-gl.css";
import province from '../../helpers/provinces'
import Map, {
  Marker,
  NavigationControl,
  Popup,
  FullscreenControl,
  GeolocateControl,
  // MapRef
} from "react-map-gl";

// import mapboxgl from '!mapbox-gl';

export const Home = () => {
  // toast?.configure()
  const mapRef = useRef();
  const token = localStorage.getItem("USER_KEY");
  // console.log({token})
  const [lng, setLng] = useState(102.630867);
  const [lat, setLat] = useState(17.974855);
  const [isExpand, setIsExpand] = useState("")
  const [tin, setTin] = useState("")
  const ORG_CD = localStorage.getItem("ORG_CD")
  const USER_LOGIN = localStorage.getItem("USER_LOGIN")
  const [showPopup, setShowPopup] = useState(false)
  const [data, setData] = useState({})
  const [isShowModal, setIsShowModal] = useState(false)
  const [provinces, setProvinces] = useState([])
  const [provinceCode, setProvinceCode] = useState('')
  const [districts, setDistricts] = useState([])
  const [districtCode, setDistrictCode] = useState('')
  const [zoom, setZoom] = useState(10)
  const [tinList, setTinList] = useState([])



  // const socket = io("ws://172.28.14.87:8765");
  // var client = new WebSocketClient();
  // const ws = new WebSocket('wss://io.mmoney.la');

  const _onSearchData = async () => {
    try {
      let data = {
        TIN: tin,
        ORG_CD,
        USER_ID: USER_LOGIN
      }
      const search = await axios.post("/tin_info_detail", data, {
        headers: {
          "x-access-token": token
        },
      })
      // console.log({ search })
      if (search?.data?.status == true) {
        setData(search?.data?.data?.TinInfo[0])
        // console.log({ search })
        if (search?.data?.data?.TinInfo[0]?.ADDR_LOCATION != "NA") {
          setZoom(14)
          const lngs = search?.data?.data?.TinInfo[0]?.ADDR_LOCATION?.split(",")[1]?.trim()
          const lats = search?.data?.data?.TinInfo[0]?.ADDR_LOCATION?.split(",")[0]?.trim()
          setLng(lngs)
          setLat(lats)
          setTinList([...tinList, search?.data?.data?.TinInfo[0]])
          // { "lng": 102.630867, "lat": 17.974855 },
          setShowPopup(true)

          // mapRef.current?.flyTo({ center: [102.61434283107519, 17.96524353757371], duration: 2000, zoom: 14 })
          mapRef.current?.flyTo({ center: [lngs, lats], duration: 2000, zoom: 14 });
        } else {
          setIsShowModal(true)
        }
      } else {
        toast.error('Runtime error', {
          position: toast.POSITION.TOP_RIGHT, autoClose: 1500
        })
      }
    } catch (error) {
      // console.log({ error })
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT, autoClose: 1500
      })
    }
  }

  const _onClickPin = async (TIN) => {
    try {
      let data = {
        TIN,
        ORG_CD,
        USER_ID: USER_LOGIN
      }
      const search = await axios.post("/tin_info_detail", data, {
        headers: {
          "x-access-token": token
        },
      })
      if (search?.data?.status == true) {
        const lngs = search?.data?.data?.TinInfo[0]?.ADDR_LOCATION?.split(",")[1]?.trim()
        const lats = search?.data?.data?.TinInfo[0]?.ADDR_LOCATION?.split(",")[0]?.trim()
        setLng(lngs)
        setLat(lats)
        setData(search?.data?.data?.TinInfo[0])
      } else {
        toast.error('Runtime error', {
          position: toast.POSITION.TOP_RIGHT, autoClose: 1500
        })
      }
    } catch (error) {
      // console.log({ error })
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT, autoClose: 1500
      })
    }
  }

  // const _fetDistrict = async () => {
  //   if (provinceCode != '') {
  //     const data = await axios.post("/districts", {
  //       "ADDR_CD_PROVINCE": provinceCode
  //     }, {
  //       headers: {
  //         "x-access-token": token
  //       },
  //     })
  //     // console.log(data?.data?.data)
  //     let datas = data?.data?.data?.map(x => {
  //       let dist = province?.data?.find(y => y?.code == provinceCode)?.district_list?.find(z => z.code == x.ADDR_CD)
  //       x.lat = dist?.lat
  //       x.lng = dist?.lng
  //       return x
  //     })
  //     setDistricts(datas)
  //   }
  // }

  const _fetTinList = async () => {
    try {
      let data = {
        USER_ID: USER_LOGIN
      }
      const datas = await axios.post("/tin_info", data, {
        headers: {
          "x-access-token": token
        },
      })
      if (datas?.data?.status == true) {
        setTinList(datas?.data?.data?.TinInfo)
      } else {
        setTinList([])
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT, autoClose: 1500
      })
    }

  }


  const _selectMarker = (filing, pay) => {
    let mark = require('../../images/icons8-Red.png')
    if (filing == "Y" && pay == "Y") {
      mark = require('../../images/icons8-done.png')
    } else if (filing == "Y" && pay == "N") {
      mark = require('../../images/icons8-warning.png')
    }
    return mark
  }

  // useEffect(async () => {
  //   const data = await axios.post("/provinces", {}, {
  //     headers: {
  //       "x-access-token": token
  //     },
  //   })
  //   let datas = data?.data?.data?.map(x => {
  //     let pro = province?.data?.find(y => y?.code == x?.ADDR_CD)
  //     x.lat = pro?.lat
  //     x.lng = pro?.lng
  //     return x
  //   })
  //   setProvinces(datas)
  // }, [])

  // useEffect(() => {
  //   _fetDistrict()

  // }, [provinceCode])

  useEffect(() => {
    const ws = new WebSocket("wss://io.mmoney.la");
    ws.onmessage = function (event) {
      try {
        _fetTinList()
        // console.log("MESSAGE")
      } catch (err) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT, autoClose: 1500
        })
      }
    };
    return () => ws.close();
  }, [])


  useEffect(async () => {
    _fetTinList()
  }, [])



  // console.log({ provinces })

  return (
    <div>
      {/* <div style={{ width: 200, height: '100vh', overflowY: "scroll" }}> */}
      <div className="card m-0 p-1" style={{ position: 'absolute', zIndex: 1 }}  >
        <div className="card-body m-0 p-1">
          <input type="text" className="form-control" placeholder="TIN:290873...." onChange={(e) => setTin(e.target.value)} onKeyDown={(e) => {
            if (e.key === "Enter" && tin.length > 0) {
              _onSearchData()
            }
          }} />
        </div>
      </div>
      {/* <button onClick={notify}>Notify!</button> */}
      {/* {provinces?.map(x => {
          return (
            <div style={{ cursor: 'pointer' }} className="card" onClick={async () => {
              setProvinceCode(x?.ADDR_CD)
              // await _fetDistrict()
              setIsExpand(isExpand == x?.ADDR_CD ? "" : x?.ADDR_CD)
            }}>
              <div onClick={(e) => {
                mapRef.current?.flyTo({ center: [x?.lng, x?.lat], duration: 2000, zoom: 14 })
              }} class="card-body">
                {x.ADDR_CD_NM}
              </div>
              <div style={{ display: isExpand == x?.ADDR_CD ? 'flex' : 'none', flexDirection: 'column', alignItems: 'flex-end', paddingBottom: 20 }}>
                {districts?.map(y => {
                  return (
                    <div style={{ cursor: 'pointer', width: 180 }} className="card" onClick={(e) => {
                      e.stopPropagation()
                      mapRef.current?.flyTo({ center: [y?.lng, y?.lat], duration: 2000, zoom: 14 });
                    }}>
                      <div class="card-body" >
                        {y.ADDR_CD_NM}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })} */}
      {/* </div> */}
      <Map
        ref={mapRef}
        mapboxAccessToken={"pk.eyJ1Ijoia2hhbXBob25lcHR2cyIsImEiOiJjbDZwNHZxYnEwMGVrM2RweWJiMnVkcTR0In0.6stFU_sCV3L53zPZOJyyvA"}
        style={{
          width: window.innerWidth,
          height: window.innerHeight
        }}
        initialViewState={{
          longitude: 102.63679811606717,
          latitude: 17.98023008389313,
          zoom: 10
        }}
        // zoom={1}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        // mapStyle="mapbox://styles/mapbox/light-v10"
      >
        {
          tinList && tinList?.map((x,i) => {
            let lat = x?.ADDR_LOCATION?.split(",")[0]?.trim()
            let lng = x?.ADDR_LOCATION?.split(",")[1]?.trim()
            // console.log({ x })
            return (
              <Marker longitude={lng} latitude={lat} key={i}>
                <img width={30} src={_selectMarker(x?.FILING_YN, x?.PAY_YN)}
                  onClick={() => {
                    _onClickPin(x?.TIN)
                    setShowPopup(!showPopup)
                  }}
                />
              </Marker>
            )
          }

          )
        }

        <NavigationControl position="bottom-right" />
        <FullscreenControl />
        <GeolocateControl trackUserLocation={true} />
        {showPopup && (
          <Popup
            // closeOnMove={true}
            maxWidth="700px"
            className="popup"
            longitude={lng} latitude={lat}
            anchor="bottom"
            onClose={() => setShowPopup(!showPopup)}
          >
            <TinInfo data={data} />
          </Popup>)}
      </Map>
      <ToastContainer />
      <AddModal isShow={isShowModal} handleClose={() => setIsShowModal(false)} data={data} />
    </div >
  );
};
